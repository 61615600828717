@import "../../styles.scss";

.top__nav{
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  transition: $transition;
  &.nav__white{
    background-color: rgba($color: white, $alpha: 1);
    backdrop-filter: blur(15px);
  }
  .logo{
    width: 50%;
    height: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1{
      font-size: 1.5rem;
      color: $bg-color;    
    }
    small{
      font-size:1rem ;
      font-weight: 100;
      color: $bg-color;
    }
  }
  .burger__menu{
    position: absolute;
    right:10px;
   button{
    background-color: transparent;
    outline: none;
    border: none;
    transition: $transition;
    
   }
  }
}
.mobile__overlay{
 display: none;
  
  &.is-open{
    height: 100vh;
    width: 50%;
    background-color: rgba($color: #ffffff, $alpha: 1);
    backdrop-filter: blur(15px);
    z-index: 1000;
    position: fixed;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
 
    overflow-y: hidden;
  }
  
  ul{
    list-style: none;
    
    li{
      text-decoration: none;
      font-size: 2.1rem;
      
     .link__to{
      text-decoration: none;
      color: $bg-color;
     }
    }
  }
  .social__icons{
    position: absolute;
    bottom: 100px;
    display: flex;
    gap:15px;
    .icon{
      font-size: 1.15rem;
      color: $bg-color;
    }
  }
}
.full__screen-menu{
  display: none;
}

@media  (min-width:1024px) {
  .top__nav{
  
    justify-content: space-between;
    .logo{
    
      flex-direction: row;
      gap: 5px;
      justify-content: flex-start;
      h1{
        font-size: 2rem;
        margin-left: 50px;
      }
      small{
        font-size: 1.25rem;
      }
    }
  }
  .full__screen-menu{
    width: 50%;
    height: 100%;
  
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ul{
      display: flex;
      margin-right: 50px;
      gap: 20px;
      list-style: none;
      .link__to{
        text-decoration: none;
        color: $bg-color;
        font-size: 1.05rem;
        font-family: 'MontserratBold';
        font-weight: 900;
      }
    }
  }
  .burger__menu{
    display: none;
  }
  
}

@media  (min-width:1024px){
  .logo{
    margin-left: 50px;
    margin-top: 10px;
  }
  .full__screen-menu{
    margin-top: 10px;
    ul{
      margin-right: 100px;
      gap: 50px;

      .link__to{
        font-size: 1.15rem;

      }
    }
  }
}