@import '../../styles.scss';

.full__image__container{
    height: 100%;
    width:100%;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;
    z-index: 10;
    img{
        width: 90%;
        height: 80%;
        object-fit: cover;
        

    }


   

}