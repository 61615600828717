@import '../../styles.scss';

.contact__container{
    display:flex;
   
    height: 100%;
    width: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
    .contact__image-block{
        display: none;
    }
    .contact__form{
        width: 100%;
        height: 70%;
        display: flex;
        padding: 20px;
        form{
            display: flex;
            flex-direction: column;
            width: 100%;            
         
            padding: 0 20px;
            justify-content: center;
            // background-color: rgba($color: #000000, $alpha: .2);
            border-radius: 6px;
            h3{
                font-size: 2rem;
                margin-bottom: 10px ;
            }
            label{
                margin-bottom: 10px;
                margin-top: 10px;
                font-size: 1.25rem;
                
            }
            input{
                height: 30px;
                border: 1px solid $bg-color;
                border-radius: 3px;
                transition: $transition;
                font-weight: 600;
                &:focus{
                    border:2px solid  $bg-color;
                    background-color: rgba($color: #000000, $alpha: .021);
                }
            }
            textarea{
                border: 1px solid $bg-color;
                border-radius: 3px;
                transition: $transition;
                font-weight: 600;
                &:focus{
                    border:2px solid  $bg-color;
                    background-color: rgba($color: #000000, $alpha: .021);
                }

            }
            .button__area{
                width: 100%;
                margin-top: 20px;
                height: 40px;
                // background-color: green;
                display: flex;
                justify-content: flex-end;

                .btn__primary{
                    width: 30%;
                    font-size: 1.15rem;
                    font-family: 'MontserratBold';
                    background-color: white;
                    border:1px solid $bg-color;
                    border-radius: 2px;
                    transition: $transition;

                    &:hover{
                        background-color: $bg-color;
                        color: white;
                    }

                }

            }
        }
    }
}

@media(min-width:1024px){
    .contact__container{
        flex-direction: row;
        padding: 20px;
        .contact__form{
            width: 50%;
        }
        .contact__image-block{
            display: flex;
            height: 65%;
            width: 50%;
          
            img{
                height: 100%;
                width: 100%;
                border-radius: 6px;
            }
        }
    }
}

@media(min-width:1920px){
    .contact__container{
        flex-direction: row;
        padding: 20px;
        gap: 10px;
        .contact__form{
            width: 40%;
        }
        .contact__image-block{
            display: flex;
            height: 65%;
            width: 40%;
          
            img{
                height: 100%;
                width: 100%;
                border-radius: 6px;
            }
        }
    }
}