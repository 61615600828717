@import '../../styles.scss';

.about__block{
 height: 100vh;
 width: 100%;
//  background-color: red;
 display: flex;
 flex-direction: column;
 justify-content: center;

 .about__img{
    height: 50%;
  
    padding: 10px;
    img{
        height: 100%;
        width: 100%;
    }
 }
 .about__me-text{
    margin-top: 10px;
    padding: 10px;
    h1{
        font-size: 2rem;
    }
    p{
        font-family: 'MontserratRegular';
        font-weight: 600;
        font-size: .91rem;
    }
    .button__holder{
        width: 100%;
        margin-top: 10px;
        
        display: flex;
        height: 30px;
        gap: 20px;
       
        .contact{
            text-decoration: none;
            font-size: 1.5rem;
            color: $bg-color;
        }
        .gallery{
            height: 100%;
            text-decoration: none;
            font-size: 1.5rem;
            background-color: #fff;
            padding-right: 10px;
            padding-left: 10px;
            border: 1px solid $bg-color;
            border-radius: 2px;
            color: $bg-color;
        }
    }
 }
   
}

@media(min-width:1024px){
    .about__block{
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .about__img{
            width: 50%;
            height: 70%;

        }
        .about__me-text{
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // background-color: aqua;
            h1{
                font-size: 3rem;

            }
            p{
                font-size: 1.12rem;
            }
            .button__holder{
                margin-top: 30px;
                height: 40px;
                // background-color: red;
                align-items: center;
                

                .contact{
                    font-size: 1.6rem;
                }
                .gallery{
                    display: flex;
                    font-size: 1.6rem;
                    align-items: center;
                    
                }
            }
        }
    }

}
@media(min-width:1920px){
    .about__block{
        flex-direction: row-reverse;
        .about__img{
            width: 40%;
        }
        .about__me-text{
            width: 40%;
            h1{
                font-size: 4rem;
                letter-spacing: 2px;
            }
            p{
                font-size: 1.4rem;
                padding-right: 20px;
            }
            .button__holder{
                
                height: 60px;
                padding: 10px 0;
                .gallery{
                    font-size: 1.82rem;
                    transition: $transition;
                    padding: 0 20px;
                    &:hover{
                        background-color: $bg-color;
                        color: white;
                    }
                }
                .contact{
                    font-size: 1.82rem;                   
                    padding: 0 10px;
                    transition: $transition;
                    &:hover{
                       background-color: $bg-color;
                       color: white;
                       border-radius: 2px;
                    }
                }
            }
        }
    }
}