@import './styles.scss';

@font-face {
    font-family: "MontserratRegular";
    src: url("../public/fonts/Montserrat-Regular.ttf") format("truetype");
      
    font-weight: normal;
  }
@font-face {
    font-family: "BabasNeue";
    src: url("../public/fonts/BebasNeue-Regular.ttf") format("truetype");
      
    font-weight: normal;
  }
 
 body {
  margin: 0;
  font-family: 'BabasNeue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:'BabasNeue', sans-serif;
}
