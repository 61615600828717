$primary-color-yellow: #b89d06;
$bg-color: #121220;
$bg-color-variant: #121;
$color-primary: #4db5ff;
$color-primary-variant: rgba(77, 181, 255, 0.4);
$color-white: #fff;
$color-light: rgba(255, 255, 255, 0.6);
$container-width-lg: 75%;
$container-width-md: 85%;
$container-width-sm: 90%;
$transition: all 400ms ease;


$layout-breakpoint-tablet: 768px;
$layout-breakpoint-phone:  425px;

@import 'animate.css';



@font-face {
    font-family: "BabasNeue";
    src: url("../src/assets/fonts/BebasNeue-Regular.ttf") format("truetype");
      
    font-weight: normal;
  }
  @font-face {
    font-family: "MontserratBold";
    src: url("../src/assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
      
    font-weight: normal;
  }
  
  @font-face {
    font-family: "MontserratRegular";
    src: url("../src/assets/fonts/Montserrat-Regular.ttf") format("truetype");
      
    font-weight: normal;
  }
  

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   


    
}

body {
    background-color: $color-white;
    color: $bg-color;
   
}




// Slide in/ out animation for each page
.slide-in {
    height: 100%;
    width: 100%;
    background-color: $bg-color;
    display: block;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    transform-origin: center;

}