@import '../../styles.scss'; 

.footer{
    display: flex;
    justify-content: flex-end; 
    height: 50px;
    width:100%;
    position: fixed;
    bottom: 0;
    
    background-color: rgba(255,255,255, 0.84);
    backdrop-filter: blur(10px);

    .footer__icons{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%; 
        width: 100%;
        gap: 2rem;
        margin-right: 50px;

        a{
            
            color: $bg-color;
            .icon{
                font-size: 1.25rem;
                transition: $transition;
                &:hover{
                    color: $color-primary;
                }
            }
        }

        // background-color: blue;
    }
}